
import { LitElement, html, css } from 'lit';
import { User } from './app.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

export class IndexElement extends LitElement {

    static properties = {
        user: { type: User },
    };

    constructor() {
        super();
        this.user = new User();
    }

    async firstUpdated() {
        this.user = await User.getInstance().ensureInitialized();
        this.requestUpdate();
    }

    static styles = css`
        #promo-title {
            font-size: 1.5rem;
             padding: 1em 0 0 0;
            color: #333;
            font-family: 'Times New Roman', Times, serif;
            font-style: italic;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 8px;
        }
    `;

    render() {
        return html`
            <div id="promo-title">
                <div>
                    ${!this.user.is_logged_in ? html`
                        <sign-up-element></sign-up-element>
                    ` : ''}
                    ${(!this.user.is_author && this.user.is_logged_in && this.user.is_subscribed) ? html`
                        ${this.user.out_of_quota() ? html`
                            <sl-button href="./payment.html" variant="primary" size="large">Upgrade to see more</sl-button>
                        ` : html`
                            <sl-button href="./library.html" variant="primary" size="large">Start reading</sl-button>
                            or
                            <sl-button href="./payment.html">Subscribe</sl-button>
                        `}
                    ` : ''}
                    ${(this.user.is_author && this.user.is_logged_in ) ? html`
                        <sl-button href="./library.html" variant="primary" size="large">Add some reviews to the library</sl-button>
                    ` : ''}
                </div>
            </div>
        `;
    }
}

customElements.define('index-element', IndexElement);
